// src/pages/SalesZSM.js
import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Select,
  MenuItem,
  Button,
  ButtonGroup,
} from "@mui/material";
import { Link } from "react-router-dom";
import PerfomrmanceTable2 from "./PerfomrmanceTable2";
import ETBRChart from "./ETBRChart";
import ChannelEnablers from "./ChannelEnablers";
import CreditUtilizationTable from "./CreditUtilizationTable";

const SalesZSM = () => {
  return (
    <div style={{ padding: "20px" }}>
      {/* Navigation Tabs */}
      <Tabs
        value={false} // Can be updated to manage state of active tab
        textColor="primary"
        indicatorColor="primary"
        style={{ marginBottom: "20px" }}
      >
        <Tab label="HO: Overview" component={Link} to="/" />
        <Tab label="Sales ZSM/RSM Review" component={Link} to="/sales-zsm" />
        <Tab label="Customer 360" component={Link} to="/customer360" />
      </Tabs>

      {/* Page Title */}
      <Typography variant="h4" gutterBottom>
        Sales ZSM/RSM Review
      </Typography>

      {/* Button Group for Toggle */}
      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        style={{ marginBottom: "20px", borderRadius: "30px", padding: "20px" }}
      >
        <Button
          style={{
            borderRadius: "30px",
            textTransform: "none",
            padding: "10px 30px",
            fontWeight: "bold",
            marginRight: "10px", // Add space between buttons
            borderColor: "#d3d3d3", // Define border color
          }}
        >
          Business Performance
        </Button>
        <Button
          style={{
            borderRadius: "30px",
            textTransform: "none",
            padding: "10px 30px",
            fontWeight: "bold",
            backgroundColor: "#f0f0f0", // Add this to give active state styling
            marginRight: "10px", // Add space between buttons
            borderColor: "#d3d3d3", // Define border color
          }}
        >
          Operating Performance
        </Button>
        <Button
          style={{
            borderRadius: "30px",
            textTransform: "none",
            padding: "10px 30px",
            fontWeight: "bold",
            borderColor: "#d3d3d3", // Define border color
          }}
        >
          Customer Experience
        </Button>
      </ButtonGroup>

      {/* Filters Section */}
      <Grid
        container
        spacing={2}
        style={{
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", // Spread items to push the last filter to the right
        }}
      >
        <Grid item xs>
          <Grid container spacing={2}>
            <Grid item>
              <Select
                defaultValue="Select Zones"
                style={{
                  backgroundColor: "white",
                  width: "140px",
                  height: "35px",
                  fontSize: "12px",
                  padding: "4px 6px",
                }}
              >
                <MenuItem value="Select Zones">Select Zones</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <Select
                defaultValue="RSMs/ROs (14)"
                style={{
                  backgroundColor: "white",
                  width: "145px",
                  height: "35px",
                  fontSize: "12px",
                  padding: "4px 6px",
                }}
              >
                <MenuItem value="RSMs/ROs (14)">RSMs/ROs (14)</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <Select
                defaultValue="Products (2)"
                style={{
                  backgroundColor: "white",
                  width: "140px",
                  height: "35px",
                  fontSize: "12px",
                  padding: "4px 6px",
                }}
              >
                <MenuItem value="Products (2)">Products (2)</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <Select
                defaultValue="Variants (50)"
                style={{
                  backgroundColor: "white",
                  width: "140px",
                  height: "35px",
                  fontSize: "12px",
                  padding: "4px 6px",
                }}
              >
                <MenuItem value="Variants (50)">Variants (50)</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <Select
                defaultValue="Budget"
                style={{
                  backgroundColor: "white",
                  width: "140px",
                  height: "35px",
                  fontSize: "12px",
                  padding: "4px 6px",
                }}
              >
                <MenuItem value="Budget">Budget</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>

        {/* Last filter (Mar 2024) moved to the far right */}
        <Grid item>
          <Select
            defaultValue="Mar 2024"
            style={{
              backgroundColor: "white",
              width: "140px",
              height: "35px",
              fontSize: "12px",
              padding: "4px 6px",
            }}
          >
            <MenuItem value="Mar 2024">Mar 2024</MenuItem>
          </Select>
        </Grid>
      </Grid>

      {/* Performance Table and Chart */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <PerfomrmanceTable2 />
        </Grid>
        <Grid item xs={12} md={6} style={{ marginTop: "60px" }}>
          <Card>
            <CardContent>
              <Typography variant="h6">ETBR by Enquiry Type</Typography>
              <ETBRChart />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Channel Enablers Section */}
      <ChannelEnablers />

      {/* Credit Utilization Section */}
      <CreditUtilizationTable />
    </div>
  );
};

export default SalesZSM;
