import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Grid,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'; // Import the dropdown icon

// Helper function to get the color based on dummy data
const getColor = (value) => {
  if (value === 'XXXX') return 'red';       // Missed target by >10%
  if (value === 'YYYY') return 'yellow';    // Missed target by 2-10%
  if (value === 'ZZZZ') return 'green';     // Target Achieved / within ±2%
  return 'black';                           // Target Ref unavailable or default
};

const PerformanceTable = () => {
  const data = [
    {
      product: 'SUV Total',
      billingVolume: 'XXXX',
      enquiry: 'XXXX',
      testDrive: 'YYYY',
      booking: 'XXXX',
      retail: 'XXXX',
      mm: 'XXXX',
      dealer: 'XXXX',
      isSubtotal: true,
    },
    {
      product: 'XUV 700',
      billingVolume: 'XXXX',
      enquiry: 'XXXX',
      testDrive: 'YYYY',
      booking: 'XXXX',
      retail: 'XXXX',
      mm: 'XXXX',
      dealer: 'XXXX',
      isSubtotal: false,
    },
    {
      product: 'XUV 500',
      billingVolume: 'ZZZZ',
      enquiry: 'ZZZZ',
      testDrive: 'YYYY',
      booking: 'YYYY',
      retail: 'ZZZZ',
      mm: 'ZZZZ',
      dealer: 'YYYY',
      isSubtotal: false,
    },
    {
      product: 'Bolero',
      billingVolume: 'ZZZZ',
      enquiry: 'ZZZZ',
      testDrive: 'ZZZZ',
      booking: 'ZZZZ',
      retail: 'ZZZZ',
      mm: 'ZZZZ',
      dealer: 'ZZZZ',
      isSubtotal: false,
    },
    {
      product: 'XUV 3XO',
      billingVolume: 'XXXX',
      enquiry: 'XXXX',
      testDrive: 'XXXX',
      booking: 'XXXX',
      retail: 'XXXX',
      mm: 'XXXX',
      dealer: 'XXXX',
      isSubtotal: false,
    },
    {
      product: 'Thar',
      billingVolume: 'ZZZZ',
      enquiry: 'ZZZZ',
      testDrive: 'YYYY',
      booking: 'YYYY',
      retail: 'ZZZZ',
      mm: 'ZZZZ',
      dealer: 'ZZZZ',
      isSubtotal: false,
    },
    {
      product: 'Pik-up Total',
      billingVolume: 'ZZZZ',
      enquiry: 'ZZZZ',
      testDrive: 'YYYY',
      booking: 'YYYY',
      retail: 'ZZZZ',
      mm: 'ZZZZ',
      dealer: 'ZZZZ',
      isSubtotal: true,
    },
    {
      product: 'Supro Total',
      billingVolume: 'ZZZZ',
      enquiry: 'ZZZZ',
      testDrive: 'YYYY',
      booking: 'XXXX',
      retail: 'XXXX',
      mm: 'XXXX',
      dealer: 'XXXX',
      isSubtotal: true,
    },
    {
      product: 'SUV + Pik-up + Supro',
      billingVolume: 'ZZZZ',
      enquiry: 'ZZZZ',
      testDrive: 'YYYY',
      booking: 'XXXX',
      retail: 'XXXX',
      mm: 'XXXX',
      dealer: 'XXXX',
      isSubtotal: true,
    },
  ];

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: '#e0e0e0' }}> {/* Main header background */}
              <TableCell><strong>Products</strong></TableCell>
              <TableCell><strong>Billing Volume</strong></TableCell>
              <TableCell><strong>Enquiry</strong></TableCell>
              <TableCell><strong>Test Drive</strong></TableCell>
              <TableCell><strong>Booking</strong></TableCell>
              <TableCell><strong>Retail</strong></TableCell>
              <TableCell><strong>M&M</strong></TableCell>
              <TableCell><strong>Dealer</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell style={{
                  fontWeight: row.isSubtotal ? 'bold' : 'normal',
                  paddingLeft: row.isSubtotal ? '10px' : '30px', // Left space for subheadings and more for sub-products
                  display: 'flex', // To align the dropdown icon and text
                  alignItems: 'center',
                }}>
                  {row.product}
                  {!row.isSubtotal && <ArrowDropDownIcon style={{ }} />} {/* Dropdown icon */}

                </TableCell>
                <TableCell style={{ color: getColor(row.billingVolume) }}>{row.billingVolume}</TableCell>
                <TableCell style={{ color: getColor(row.enquiry) }}>{row.enquiry}</TableCell>
                <TableCell style={{ color: getColor(row.testDrive) }}>{row.testDrive}</TableCell>
                <TableCell style={{ color: getColor(row.booking) }}>{row.booking}</TableCell>
                <TableCell style={{ color: getColor(row.retail) }}>{row.retail}</TableCell>
                <TableCell style={{ color: getColor(row.mm) }}>{row.mm}</TableCell>
                <TableCell style={{ color: getColor(row.dealer) }}>{row.dealer}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Legend Section */}
      <Box marginTop={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Box display="flex" alignItems="center">
              <Box sx={{ width: 10, height: 10, backgroundColor: 'red', borderRadius: '50%', marginRight: 1 }} />
              <Typography>Missed target by &gt;10%</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" alignItems="center">
              <Box sx={{ width: 10, height: 10, backgroundColor: 'yellow', borderRadius: '50%', marginRight: 1 }} />
              <Typography>Missed target by 2-10%</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" alignItems="center">
              <Box sx={{ width: 10, height: 10, backgroundColor: 'green', borderRadius: '50%', marginRight: 1 }} />
              <Typography>Target Achieved/within ±2% of target</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" alignItems="center">
              <Box sx={{ width: 10, height: 10, backgroundColor: 'black', borderRadius: '50%', marginRight: 1 }} />
              <Typography>Target Ref unavailable</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default PerformanceTable;
