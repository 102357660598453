// src/components/ChannelEnablers.js
import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Box,
  Checkbox,
  IconButton,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ChannelEnablers = () => {
  return (
    <div style={{ marginTop: "40px" }}>
      <Typography variant="h6" gutterBottom>
        Channel Enablers
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <Card>
            <CardContent>
              <Typography variant="body2">
                Channel (Showroom) Adequacy
              </Typography>
              <Grid container spacing={2} alignItems="center" marginTop={0.4}>
                <Grid
                  item
                  xs={4}
                  container
                  direction="column"
                  alignItems="center"
                >
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    MD
                  </Typography>
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    1040 / 1320
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  direction="column"
                  alignItems="center"
                >
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    Cube
                  </Typography>
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    1040 / 1320
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  direction="column"
                  alignItems="center"
                >
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    Secondary
                  </Typography>
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    1040 / 1320
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="body2">Dealer Manpower Adequacy</Typography>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", marginTop: "2rem" }}
              >
                5020 / 6020
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="body2">
                Dealer Working Capital Funds
              </Typography>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", marginTop: "2rem" }}
              >
                ~5520 / 4030
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card sx={{ height: "100%"}}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography variant="h6" component="div" fontSize="16px">
                    Dealer Health Index
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton size="small" sx={{ marginLeft: 38.5 }}>
                    <MoreVertIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
              <Box display="flex" flexDirection="column" gap={1} marginTop={2}>
                <Box display="flex" flexDirection="column" width="100%">
                  <Box
                    display="flex"
                    width="100%"
                    height={10}
                    marginBottom={0.5}
                    borderRadius="4px"
                    overflow="hidden"
                  >
                    <Box width="15%" height="100%" bgcolor="#7f93c4" />
                    <Box width="25%" height="100%" bgcolor="#9e6ea5" />
                    <Box width="35%" height="100%" bgcolor="#4889eb" />
                    <Box width="25%" height="100%" bgcolor="#76cdd8" />
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      marginRight={0.5}
                    >
                      15%
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      marginRight={0.5}
                    >
                      25%
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      marginRight={0.5}
                    >
                      35%
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      25%
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* Color Legend */}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                marginTop={2}
              >
                <Box display="flex" alignItems="center" marginRight={2}>
                  <Box
                    width={15}
                    height={15}
                    bgcolor="#7f93c4"
                    marginRight={1}
                  />
                  <Typography variant="body2" color="textSecondary">
                    DDD
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" marginRight={2}>
                  <Box
                    width={15}
                    height={15}
                    bgcolor="#9e6ea5"
                    marginRight={1}
                  />
                  <Typography variant="body2" color="textSecondary">
                    CCC
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" marginRight={2}>
                  <Box
                    width={15}
                    height={15}
                    bgcolor="#4889eb"
                    marginRight={1}
                  />
                  <Typography variant="body2" color="textSecondary">
                    BBB
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box
                    width={15}
                    height={15}
                    bgcolor="#76cdd8"
                    marginRight={1}
                  />
                  <Typography variant="body2" color="textSecondary">
                    AAA
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="body2">MITE Score</Typography>
              <Typography variant="h5" style={{marginTop:'2rem'}}>~55/60</Typography>
              <Box display="flex" alignItems="center" marginTop={1}>
                    <Typography variant="body2" component="div">
                      <Box
                        component="span"
                        padding={1}
                        bgcolor="#01a66a"
                        color="white"
                        borderRadius="4px"
                        fontSize={14}
                      >
                        +8%
                      </Box>{" "}
                      <Typography
                        variant="body2"
                        component="span"
                        fontSize={14}
                      >
                        vs last quarter
                      </Typography>{" "}
                    </Typography>
                  </Box>
                
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="body2">Credit Dependency Ratio</Typography>
              <Typography variant="h5"style={{marginTop:'2.3rem',marginBottom:'1.4rem', fontWeight:'bold'}}>10%</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ChannelEnablers;
