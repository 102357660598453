// src/components/ETBRChart.js
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Updated data with 6 months
const data = [
  { name: 'Jan', digital: 40, walkIn: 30, field: 20, telephone: 10 },
  { name: 'Feb', digital: 45, walkIn: 25, field: 20, telephone: 10 },
  { name: 'Mar', digital: 50, walkIn: 35, field: 25, telephone: 15 },
  { name: 'Apr', digital: 55, walkIn: 30, field: 20, telephone: 15 },
  { name: 'May', digital: 60, walkIn: 40, field: 30, telephone: 20 },
  { name: 'Jun', digital: 65, walkIn: 45, field: 35, telephone: 25 },
];

const ETBRChart = () => {
  return (
    <ResponsiveContainer width="100%" height={1022}>
      <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
        <XAxis dataKey="name" />
        <YAxis
          tickCount={10}
          domain={[0, 'dataMax + 10']} // Ensure Y-axis extends beyond the max data value
          tickFormatter={(tick) => `${tick}`} // Format Y-axis labels
        />
        <Tooltip /> 
        <Legend />
        <Bar dataKey="digital" stackId="a" fill="#7f93c4" barSize={30} />
        <Bar dataKey="walkIn" stackId="a" fill="#9e6ea5" barSize={30} />
        <Bar dataKey="field" stackId="a" fill="#4889eb" barSize={30} />
        <Bar dataKey="telephone" stackId="a" fill="#76cdd8" barSize={30} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ETBRChart;
