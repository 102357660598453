// src/pages/Customer360.js
import React from 'react';
import { Grid, Card, CardContent, Typography, Tabs, Tab } from '@mui/material';
import { Link } from 'react-router-dom';
const Customer360 = () => {
  return (
    <div style={{ padding: '20px' }}>
             <Tabs
        value={false} // Change based on the selected tab if you want to manage state
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="HO: Overview" component={Link} to="/" />
        <Tab label="Sales ZSM/RSM Review" component={Link} to="/sales-zsm" />
        <Tab label="Customer 360" component={Link} to="/customer360" />
      </Tabs>

      <h1>Customer 360</h1>
      {/* Add relevant content */}
    </div>
  );
};

export default Customer360;
