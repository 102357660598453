// src/components/PerformanceTable.js
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const PerfomrmanceTable2 = () => {
  // Data for the table
  const data = [
    {
      section: "ETBR",
      rows: [
        {
          metric: "Quick Enquiry Volume",
          actual: "XXXX",
          actualColor: "red",
          target: "XXXX",
          prevMonth: "XXXX",
          prevYear: "XXXX",
        },
        {
          metric: "Enquiry Volume",
          actual: "XXXX",
          actualColor: "green",
          target: "XXXX",
          prevMonth: "XXXX",
          prevYear: "XXXX",
        },
        {
          metric: "TD Volume",
          actual: "XXXX",
          actualColor: "green",
          target: "XXXX",
          prevMonth: "XXXX",
          prevYear: "XXXX",
        },
        {
          metric: "Booking Volume",
          actual: "XXXX",
          actualColor: "green",
          target: "XXXX",
          prevMonth: "XXXX",
          prevYear: "XXXX",
        },
        {
          metric: "Retail Volume",
          actual: "XXXX",
          actualColor: "yellow",
          target: "XXXX",
          prevMonth: "XXXX",
          prevYear: "XXXX",
        },
        {
          metric: "Cancellations",
          actual: "XXXX",
          actualColor: "yellow",
          target: "XXXX",
          prevMonth: "XXXX",
          prevYear: "XXXX",
        },
      ],
    },
    {
      section: "Test Drive & Enquiries",
      rows: [
        {
          metric: "Test Drive/Enquiries %",
          actual: "XXXX",
          actualColor: "green",
          target: "XXXX",
          prevMonth: "XXXX",
          prevYear: "XXXX",
        },
        {
          metric: "Bookings/Enquiries %",
          actual: "XXXX",
          actualColor: "green",
          target: "XXXX",
          prevMonth: "XXXX",
          prevYear: "XXXX",
        },
        {
          metric: "Retail/Enquiries %",
          actual: "XXXX",
          actualColor: "green",
          target: "XXXX",
          prevMonth: "XXXX",
          prevYear: "XXXX",
        },
        {
          metric: "Open Bookings",
          actual: "XXXX",
          actualColor: "green",
          target: "XXXX",
          prevMonth: "XXXX",
          prevYear: "XXXX",
        },
        {
          metric: "Cancellation/Open Bookings %",
          actual: "XXXX",
          actualColor: "green",
          target: "XXXX",
          prevMonth: "XXXX",
          prevYear: "XXXX",
        },
      ],
    },
    {
      section: "Allied Sales",
      rows: [
        {
          metric: "Accessories Retail (Rev/Vehicle)",
          actual: "XXXX",
          actualColor: "red",
          target: "XXXX",
          prevMonth: "XXXX",
          prevYear: "XXXX",
        },
        {
          metric: "Accessories Billing (Rev/Vehicle)",
          actual: "XXXX",
          actualColor: "red",
          target: "XXXX",
          prevMonth: "XXXX",
          prevYear: "XXXX",
        },
        {
          metric: "Shield Penetration %",
          actual: "XXXX",
          actualColor: "green",
          target: "XXXX",
          prevMonth: "XXXX",
          prevYear: "XXXX",
        },
        {
          metric: "RSA Penetration %",
          actual: "XXXX",
          actualColor: "green",
          target: "XXXX",
          prevMonth: "XXXX",
          prevYear: "XXXX",
        },
      ],
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="h6" gutterBottom>
        Operating Performance
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ backgroundColor: "#d5e0f6", fontWeight: "bold" }}
              >
                Metric
              </TableCell>
              <TableCell
                sx={{ backgroundColor: "#d5e0f6", fontWeight: "bold" }}
              >
                Actual
              </TableCell>
              <TableCell
                sx={{ backgroundColor: "#d5e0f6", fontWeight: "bold" }}
              >
                Target
              </TableCell>
              <TableCell
                sx={{ backgroundColor: "#d5e0f6", fontWeight: "bold" }}
              >
                Prev. Month
              </TableCell>
              <TableCell
                sx={{ backgroundColor: "#d5e0f6", fontWeight: "bold" }}
              >
                Prev. Year
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((section, index) => (
              <React.Fragment key={index}>
                {/* Section Header */}
                <TableRow>
                  <TableCell
                    colSpan={5}
                    style={{ fontWeight: "bold" }}
                  >
                    {section.section}
                  </TableCell>
                </TableRow>
                {/* Section Rows */}
                {section.rows.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{row.metric}</TableCell>
                    <TableCell style={{ color: row.actualColor }}>
                      {row.actual}
                    </TableCell>
                    <TableCell>{row.target}</TableCell>
                    <TableCell>{row.prevMonth}</TableCell>
                    <TableCell>{row.prevYear}</TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PerfomrmanceTable2;
