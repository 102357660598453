// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import SalesZSM from './SalesZSM';
import Customer360 from './Customer360';

function App() {
  return (
    <Router>
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <div style={{ flexGrow: 1 }}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/sales-zsm" element={<SalesZSM />} />
            <Route path="/customer360" element={<Customer360 />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
