// src/pages/Dashboard.js
import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Box,
  Checkbox,
  IconButton,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import PerformanceTable from "./PerformanceTable";
import KPI from "./KPI";
import InfoIcon from "@mui/icons-material/Info";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts";
import "./index.css";

const data = [
  { name: "Product Name", value: 1500 },
  { name: "Product Name", value: 1800 },
  { name: "Product Name", value: 1200 },
  { name: "Product Name", value: 2000 },
  { name: "Product Name", value: 1900 },
];

const InsightsCard = ({ title }) => (
  <Grid item xs={12} sm={6}>
    <Card sx={{ height: "100%", marginTop: 2 }}>
      <CardContent>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <Typography variant="h6" component="div" fontSize="16px">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton size="small">
              <MoreVertIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Box display="flex" marginTop={2} marginBottom={2}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#003366",
              color: "white",
              fontSize: "12px",
            }}
          >
            % Delta
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#cce5ff",
              color: "#003366",
              fontSize: "12px",
            }}
          >
            Absolute Delta
          </Button>
        </Box>
        <Box>
          <BarChart
            width={400} // Smaller width
            height={250} // Smaller height
            data={data}
            layout="vertical"
            margin={{ top: 20, right: 30, bottom: 5, left: 20 }}
          >
            <XAxis
              type="number"
              tickValues={[0, 500, 1000, 1500, 2000, 2500]}
            />
            <YAxis type="category" dataKey="name" />
            <Tooltip />
            <Bar dataKey="value" fill="#8884d8" barSize={20} />{" "}
            {/* Thinner bars */}
          </BarChart>
        </Box>
      </CardContent>
    </Card>
  </Grid>
);

const Dashboard = () => {
  return (
    <div style={{ padding: "20px" }}>
      <Tabs
        value={false} // Change based on the selected tab if you want to manage state
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="HO: Overview" component={Link} to="/" />
        <Tab label="Sales ZSM/RSM Review" component={Link} to="/sales-zsm" />
        <Tab label="Customer 360" component={Link} to="/customer360" />
      </Tabs>
      <div class="dropdown-container">
        <h5>View By:</h5>
        <div class="dropdowns">
          <select class="dropdown blue">
            <option>Products</option>
            <option>Billing Volume</option>
            <option>Dealer</option>
            <option>Booking</option>
          </select>
          <select class="dropdown white">
            <option>Products (1)</option>
            <option>Products (2)</option>
            <option>Products (3)</option>
            <option>Products (4)</option>
          </select>
          <select class="dropdown white">
            <option>ROs (20)</option>
            <option>ROs (10)</option>
            <option>ROs (30)</option>
          </select>
          <select class="dropdown white">
            <option>Budget</option>
            <option>15Lakh</option>
            <option>25Lakh</option>
          </select>
          <select class="dropdown white dropdown-month">
            <option>Jan 2024</option>
            <option>Feb 2024</option>
            <option>Mar 2024</option>
            <option>Apr 2024</option>
          </select>
        </div>
      </div>

      <h1>Sales Dashboard</h1>

      {/* Performance Table */}
      <PerformanceTable />

      <div style={{ padding: "20px" }}>
        <Grid container spacing={2}>
          {/* Collections KPI */}
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Typography variant="h6" component="div" gutterBottom>
                  Collections
                </Typography>
                <Box display="flex" alignItems="center" marginBottom={2}>
                  <Checkbox color="primary" checked size="small" />
                  <Typography variant="body2">Govt./B2B</Typography>
                  <Checkbox color="primary" size="small" />
                  <Typography variant="body2">Dealer</Typography>
                </Box>
                <Box display="flex" flexDirection="column" marginTop="2.5rem">
                  <Typography variant="h4" component="div">
                    <span style={{ fontWeight: "bold" }}>1820</span>{" "}
                    <span style={{ fontSize: "0.875rem" }}>Cr</span>
                  </Typography>
                  <Box display="flex" alignItems="center" marginTop={1}>
                    <Typography variant="body2" component="div">
                      <Box
                        component="span"
                        padding={1}
                        bgcolor="#01a66a"
                        color="white"
                        borderRadius="4px"
                        fontSize={14}
                      >
                        +8%
                      </Box>{" "}
                      <Typography
                        variant="body2"
                        component="span"
                        fontSize={14}
                      >
                        vs target
                      </Typography>{" "}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Overdue Debtors KPI */}
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Typography variant="h6" component="div" gutterBottom>
                  Overdue Debtors <i className="fas fa-info-circle"></i>
                </Typography>
                <Box display="flex" alignItems="center" marginBottom={2}>
                  <Checkbox color="primary" checked size="small" />
                  <Typography>Govt./B2B</Typography>
                  <Checkbox color="primary" size="small" />
                  <Typography>Dealer</Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  marginTop={3}
                >
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2" color="textSecondary">
                      &gt;90 Days
                    </Typography>
                    <Typography variant="h4" component="div">
                      <span style={{ fontWeight: "bold" }}>45</span>{" "}
                      <span style={{ fontSize: "0.875rem" }}>Cr</span>
                    </Typography>
                    <Box display="flex" alignItems="center" marginTop={1}>
                      <Typography variant="body2" component="div">
                        <Box
                          component="span"
                          padding={1}
                          bgcolor="#01a66a"
                          color="white"
                          borderRadius="4px"
                        >
                          -8%
                        </Box>{" "}
                        vs prev 3M Avg
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2" color="textSecondary">
                      60-90 Days
                    </Typography>
                    <Typography variant="h4" component="div">
                      <span style={{ fontWeight: "bold" }}>70</span>{" "}
                      <span style={{ fontSize: "0.875rem" }}>Cr</span>
                    </Typography>
                    <Box display="flex" alignItems="center" marginTop={1}>
                      <Typography variant="body2" component="div">
                        <Box
                          component="span"
                          padding={1}
                          bgcolor="#01a66a"
                          color="white"
                          borderRadius="4px"
                        >
                          -8%
                        </Box>{" "}
                        vs prev 3M Avg
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Billing Market Share KPI */}
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Typography variant="h6" component="div">
                  Billing Market Share <i className="fas fa-info-circle"></i>
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginTop={8.5}
                >
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2" color="textSecondary">
                      SUV
                    </Typography>
                    <Typography variant="h4" component="div">
                      <span style={{ fontWeight: "bold" }}>45</span>{" "}
                      <span style={{ fontSize: "0.875rem" }}>%</span>
                    </Typography>
                    <Box display="flex" alignItems="center" marginTop={1}>
                      <Typography variant="body2" component="div">
                        <Box
                          component="span"
                          padding={1}
                          bgcolor="#01a66a"
                          color="white"
                          borderRadius="4px"
                        >
                          +8%
                        </Box>{" "}
                        vs prev period
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2" color="textSecondary">
                      Pik-up
                    </Typography>
                    <Typography variant="h4" component="div">
                      <span style={{ fontWeight: "bold" }}>70</span>{" "}
                      <span style={{ fontSize: "0.875rem" }}>%</span>
                    </Typography>
                    <Box display="flex" alignItems="center" marginTop={1}>
                      <Typography variant="body2" component="div">
                        <Box
                          component="span"
                          padding={1}
                          bgcolor="#01a66a"
                          color="white"
                          borderRadius="4px"
                        >
                          +8%
                        </Box>{" "}
                        vs prev period
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Inventory Days */}
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Typography variant="h6" component="div" gutterBottom>
                  Inventory Days <i className="fas fa-info-circle"></i>
                </Typography>
                <Box display="flex" alignItems="center" marginBottom={2}>
                  <Checkbox color="primary" checked size="small" />
                  <Typography>Dealer</Typography>
                  <Checkbox color="primary" size="small" />
                  <Typography>M&M</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2" color="textSecondary">
                      SUV
                    </Typography>
                    <Typography variant="h5" component="div">
                      <span style={{ fontWeight: "bold", fontSize: "35px" }}>
                        30
                      </span>{" "}
                      <span style={{ fontSize: "0.875rem" }}>Days</span>
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2" color="textSecondary">
                      Pik-up
                    </Typography>
                    <Typography variant="h5" component="div">
                      <span style={{ fontWeight: "bold", fontSize: "35px" }}>
                        45
                      </span>{" "}
                      <span style={{ fontSize: "0.875rem" }}>Days</span>
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Inventory Ageing */}
          <Grid item xs={12} sm={6} md={8}>
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Typography variant="h6" component="div">
          Inventory Ageing <i className="fas fa-info-circle"></i>
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          marginBottom={2}
        >
          {/* SUV section */}
          <Box
            display="flex"
            flexDirection="column"
            width="50%"
            paddingRight={0.5}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              marginBottom={0.5}
            >
              SUV
            </Typography>
            <Box display="flex" alignItems="center" marginBottom={1}>
              <Box display="flex" alignItems="center" marginRight={1}>
                <Checkbox color="primary" checked size="small" />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  marginLeft={0.5}
                >
                  Dealer
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Checkbox color="primary" size="small" />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  marginLeft={0.5}
                >
                  M&M
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={1}>
              <Box display="flex" flexDirection="column" width="100%">
                <Box
                  display="flex"
                  width="100%"
                  height={10}
                  marginBottom={0.5}
                  borderRadius="4px"
                  overflow="hidden"
                >
                  <Box width="10%" height="100%" bgcolor="#7f93c4" />
                  <Box width="30%" height="100%" bgcolor="#9e6ea5" />
                  <Box width="20%" height="100%" bgcolor="#4889eb" />
                  <Box width="40%" height="100%" bgcolor="#76cdd8" />
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    marginRight={0.5}
                  >
                    10%
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    marginRight={0.5}
                  >
                    30%
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    marginRight={0.5}
                  >
                    20%
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    40%
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Pik-up section */}
          <Box
            display="flex"
            flexDirection="column"
            width="50%"
            paddingLeft={0.5}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              marginBottom={0.5}
            >
              Pik-up
            </Typography>
            <Box display="flex" alignItems="center" marginBottom={1}>
              <Box display="flex" alignItems="center" marginRight={1}>
                <Checkbox color="primary" checked size="small" />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  marginLeft={0.5}
                >
                  Dealer
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Checkbox color="primary" size="small" />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  marginLeft={0.5}
                >
                  M&M
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={1}>
              <Box display="flex" flexDirection="column" width="100%">
                <Box
                  display="flex"
                  width="100%"
                  height={10}
                  marginBottom={0.5}
                  borderRadius="4px"
                  overflow="hidden"
                >
                  <Box width="15%" height="100%" bgcolor="#7f93c4" />
                  <Box width="25%" height="100%" bgcolor="#9e6ea5" />
                  <Box width="35%" height="100%" bgcolor="#4889eb" />
                  <Box width="25%" height="100%" bgcolor="#76cdd8" />
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    marginRight={0.5}
                  >
                    15%
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    marginRight={0.5}
                  >
                    25%
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    marginRight={0.5}
                  >
                    35%
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    25%
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Color Legend */}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          marginTop={2}
        >
          <Box display="flex" alignItems="center" marginRight={2}>
            <Box
              width={15}
              height={15}
              bgcolor="#7f93c4"
              marginRight={1}
            />
            <Typography variant="body2" color="textSecondary">
              Less than 10 days
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" marginRight={2}>
            <Box
              width={15}
              height={15}
              bgcolor="#9e6ea5"
              marginRight={1}
            />
            <Typography variant="body2" color="textSecondary">
              10-20 days
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" marginRight={2}>
            <Box
              width={15}
              height={15}
              bgcolor="#4889eb"
              marginRight={1}
            />
            <Typography variant="body2" color="textSecondary">
              20-30 days
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box
              width={15}
              height={15}
              bgcolor="#76cdd8"
              marginRight={1}
            />
            <Typography variant="body2" color="textSecondary">
              More than 30 days
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  </Grid>
  </Grid>
        <Grid container spacing={2}>
          {/* Adding Enablers Header */}
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="div"
              marginTop={2}
              fontSize={16}
              fontWeight={600}
            >
              Enablers
            </Typography>
          </Grid>
          {/* First Card (50% width) */}
          <Grid item xs={12} md={6}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography variant="h6" component="div" fontSize="16px">
                      Channel (Showroom) Adequacy
                    </Typography>
                  </Grid>
                  {/* <Grid item>
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Grid> */}
                  <Grid item>
                    <IconButton size="small" sx={{ marginLeft: 28 }}>
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    marginTop={1}
                  >
                    <Typography variant="body2">MD</Typography>
                    <Typography variant="body2">Cube</Typography>
                    <Typography variant="body2">Secondary</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    mt={0.5}
                  >
                    <Typography variant="body2" sx={{ textAlign: "center" }}>
                      -
                    </Typography>
                    <Typography variant="body2" sx={{ textAlign: "center" }}>
                      -
                    </Typography>
                    <Typography variant="body2" sx={{ textAlign: "center" }}>
                      -
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {/* Second Card (25% width) */}
          <Grid item xs={12} md={3}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography variant="h6" component="div" fontSize="16px">
                      MITE Score
                    </Typography>
                  </Grid>
                  {/* <Grid item>
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Grid> */}
                  <Grid item>
                    <IconButton size="small" sx={{ marginLeft: 4.8 }}>
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Typography
                  variant="body2"
                  sx={{ marginTop: "2rem", fontWeight: "600" }}
                >
                  -
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* Third Card (25% width) */}
          <Grid item xs={12} md={3}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography variant="h6" component="div" fontSize="16px">
                      Credit Dependency Ratio
                    </Typography>
                  </Grid>
                  {/* <Grid item>
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Grid> */}
                  <Grid item>
                    <IconButton size="small">
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Typography
                  variant="body2"
                  sx={{ marginTop: "2rem", fontWeight: "600" }}
                >
                  -
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* First Card */}
          <Grid item xs={12} sm={6}>
            <Card sx={{ height: "100%", marginTop: 2 }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography variant="h6" component="div" fontSize="16px">
                      Dealer Health Index
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton size="small" sx={{ marginLeft: 38.5 }}>
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={1}
                  marginTop={2}
                >
                  <Box display="flex" flexDirection="column" width="100%">
                    <Box
                      display="flex"
                      width="100%"
                      height={10}
                      marginBottom={0.5}
                      borderRadius="4px"
                      overflow="hidden"
                    >
                      <Box width="15%" height="100%" bgcolor="#7f93c4" />
                      <Box width="25%" height="100%" bgcolor="#9e6ea5" />
                      <Box width="35%" height="100%" bgcolor="#4889eb" />
                      <Box width="25%" height="100%" bgcolor="#76cdd8" />
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        marginRight={0.5}
                      >
                        15%
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        marginRight={0.5}
                      >
                        25%
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        marginRight={0.5}
                      >
                        35%
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        25%
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {/* Color Legend */}
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  marginTop={2}
                >
                  <Box display="flex" alignItems="center" marginRight={2}>
                    <Box
                      width={15}
                      height={15}
                      bgcolor="#7f93c4"
                      marginRight={1}
                    />
                    <Typography variant="body2" color="textSecondary">
                      DDD
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" marginRight={2}>
                    <Box
                      width={15}
                      height={15}
                      bgcolor="#9e6ea5"
                      marginRight={1}
                    />
                    <Typography variant="body2" color="textSecondary">
                      CCC
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" marginRight={2}>
                    <Box
                      width={15}
                      height={15}
                      bgcolor="#4889eb"
                      marginRight={1}
                    />
                    <Typography variant="body2" color="textSecondary">
                      BBB
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box
                      width={15}
                      height={15}
                      bgcolor="#76cdd8"
                      marginRight={1}
                    />
                    <Typography variant="body2" color="textSecondary">
                      AAA
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Second Card */}
          <Grid item xs={12} sm={6}>
            <Card sx={{ height: "100%", marginTop: 2 }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography variant="h6" component="div" fontSize="16px">
                      Dealer Manpower Adequacy
                    </Typography>
                  </Grid>
                  {/* <Grid item>
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Grid> */}
                  <Grid item>
                    <IconButton size="small" sx={{ marginLeft: 31 }}>
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Typography
                  variant="body2"
                  sx={{ marginTop: "2rem", fontWeight: "600" }}
                >
                  -
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="div"
              fontSize="16px"
              marginTop={5}
            >
              Insights
            </Typography>
          </Grid>
          <InsightsCard title="Billing Volume by Products: Bottom Performers" />
          <InsightsCard title="Retail Volume by Products: Bottom Performers" />
        </Grid>
      </div>
    </div>
  );
};

export default Dashboard;
