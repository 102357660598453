// src/components/Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@mui/material';

const Sidebar = () => {
  const styles = {
    sidebar: {
      width: '140px',
      backgroundColor: '#0C1D39', // Dark blue background
      height: '113vh',
      color: 'white',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
    },
    heading: {
      marginBottom: '20px',
      fontSize: '18px',
      fontWeight: 'bold',
    },
    listItem: {
      marginBottom: '10px',
      padding: '10px 15px',
      color: 'white',
      backgroundColor: '#2F3A54', // Default button color
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center', // Align text to center
    },
    activeItem: {
      backgroundColor: '#fff', // Active item color (Sales selected in your example)
      color: '#2F3A54',
    },
    listItemText: {
      fontSize: '14px', // Make text smaller
      textAlign: 'center', // Center align text inside ListItemText
    },
    customView: {
      marginTop: '20px',
      padding: '10px 15px',
      backgroundColor: '#2F3A54',
      color: 'white',
      borderRadius: '5px',
      textAlign: 'center',
    },
    chat: {
      marginTop: '10px',
      padding: '10px 15px',
      backgroundColor: '#2F3A54',
      color: 'white',
      borderRadius: '5px',
      textAlign: 'center',
    },
    sectionDivider: {
      borderBottom: '1px solid white',
      margin: '20px 0',
    },
  };

  return (
    <div style={styles.sidebar}>
      <h2 style={styles.heading}>Reviews</h2>
      <List>
        <ListItem
          button
          component={Link}
          to="/"
          style={{ ...styles.listItem, ...styles.activeItem }} // Active item
        >
          <ListItemText primary="Sales" style={styles.listItemText} />
        </ListItem>
        <ListItem button component={Link} to="/services" style={styles.listItem}>
          <ListItemText primary="Services" style={styles.listItemText} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/customer-experience"
          style={styles.listItem}
        >
          <ListItemText primary="Customer Experience" style={styles.listItemText} />
        </ListItem>
        {/* The rest of the disabled items */}
        <ListItem button disabled style={styles.listItem}>
          <ListItemText primary="Manufacturing" style={styles.listItemText} />
        </ListItem>
        <ListItem button disabled style={styles.listItem}>
          <ListItemText primary="Quality" style={styles.listItemText} />
        </ListItem>
        <ListItem button disabled style={styles.listItem}>
          <ListItemText primary="Sourcing" style={styles.listItemText} />
        </ListItem>
        <ListItem button disabled style={styles.listItem}>
          <ListItemText primary="Finance" style={styles.listItemText} />
        </ListItem>
        <ListItem button disabled style={styles.listItem}>
          <ListItemText primary="Governance" style={styles.listItemText} />
        </ListItem>
      </List>

      <div style={styles.sectionDivider}></div>

      <div style={styles.customView}>Custom View</div>
      <div style={styles.chat}>Chat</div>
    </div>
  );
};

export default Sidebar;
