// src/components/CreditUtilizationTable.js
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const data = [
  { ro: 'Mumbai', totalBilling: 'XXXX', creditExtended: 'XXXX', creditDependencyPrev: 'XXXX', creditDependencyCurr: 'XXXX' },
  { ro: 'RO Name 2', totalBilling: 'XXXX', creditExtended: 'XXXX', creditDependencyPrev: 'XXXX', creditDependencyCurr: 'XXXX' },
  { ro: 'RO Name 3', totalBilling: 'XXXX', creditExtended: 'XXXX', creditDependencyPrev: 'XXXX', creditDependencyCurr: 'XXXX' },
  { ro: 'RO Name 4', totalBilling: 'XXXX', creditExtended: 'XXXX', creditDependencyPrev: 'XXXX', creditDependencyCurr: 'XXXX' },
  { ro: 'RO Name 5', totalBilling: 'XXXX', creditExtended: 'XXXX', creditDependencyPrev: 'XXXX', creditDependencyCurr: 'XXXX' },
];

const CreditUtilizationTable = () => {
  return (
    <div style={{ marginTop: '40px' }}>
      <Typography variant="h6" gutterBottom>Credit Utilization: Bottom Performing ROs</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ bgcolor: '#d5e0f7' }}>
            <TableRow>
              <TableCell>RO</TableCell>
              <TableCell>Total Billing Value</TableCell>
              <TableCell>Credit Extended</TableCell>
              <TableCell>Credit Dependency Ratio (Prev. Period)</TableCell>
              <TableCell>Credit Dependency Ratio (Curr. Period)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.ro}</TableCell>
                <TableCell>{row.totalBilling}</TableCell>
                <TableCell>{row.creditExtended}</TableCell>
                <TableCell>{row.creditDependencyPrev}</TableCell>
                <TableCell>{row.creditDependencyCurr}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CreditUtilizationTable;
